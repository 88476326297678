// TODO: Figure out better way to determine environment, would be better to look
// at the pod deployed on (998) vs subdomain
export const isStagingEnv = [
  'z3n-z2staging2',
  'z3nnorthwoods',
  'zd-dev',
  'scooter',
  'localhost',
  'z3n-ultimate-poc',
  'zendesk-staging',
  'cloudhatchery',
  'zopim.org',
].find((hint) => window.location.origin.includes(hint));

/**
 * Gets the SunCo envIntegrationId for different environments
 * @returns {string} Determined envIntegrationId
 */
export const getEnvIntegrationId = () => {
  let envIntegrationId = '';
  if (isStagingEnv) {
    envIntegrationId = '64bece11ec89348864e05801';
  } else {
    envIntegrationId = '6054c95a757a0200d2deeee0';
  }

  return envIntegrationId;
};
