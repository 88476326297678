import debounce from 'lodash/debounce';
import botWidgetIconBlinking from '../images/blinking.gif';
import businessIconUrl from '../images/business-icon.png';
import botWidgetIcon from '../images/default.gif';
import botWidgetIconMessageReceivedNoBlink from '../images/message_received_noblink.gif';
import botWidgetIconTypingEnd from '../images/typing_end.gif';
import botWidgetIconTypingStart from '../images/typing_start.gif';
import { addAccessibleControls, getTranslatedText } from '../lib/accessibility';
import { createConversation, shouldCreateConversation } from '../lib/helpers';
import { closeWidgetIcon, minimizeWidgetIcon } from '../lib/icons';
import { getLoadingState, showLoadingIndicator } from '../lib/loaders';
import { error } from '../lib/logger';
import { updateConversationMetadata } from '../lib/metadata';
import {
  getIframe,
  getIframeDoc,
  hidden,
  hide,
  reloadConversation,
  show,
} from './api';
import { getSuncoAuth, login } from './auth';
import { activateFailover, getFailoverState } from './failover';

const BOT_NAMES = ['Zendesk', 'Z Bot'];
const WIDGET_CONFIG = Object.freeze({
  opened: {
    'max-width': '374px',
    width: '100%',
    height: '504px',
  },
  closed: {
    width: '75px',
    height: '100px',
  },
});
let asyncConversationsCount = 0;

/**
 * Gets the async count of conversations
 * @returns {int} Async conversation count
 */
export const getAsyncConversationsCount = () => asyncConversationsCount;

/**
 * Sets the async count of conversations
 * @param {boolean} currentCount - The current async count of conversations
 */
export const setAsyncConversationsCount = (currentCount) => {
  asyncConversationsCount = currentCount;
};

/**
 * Attaches event listeners for Z2SuncoWidget and Smooch events, see individual
 * listener methods for more info.
 * @param {{csrfToken: ?string, inProduct: boolean, inShoppingCart: boolean,
 * jwtUrl: ?string, locale: ?string, openOnInit: boolean,
 * openWidgetToNewConversation: boolean, specificIntent: ?string, widgetInputs:
 * {widgetInput1: ?string, widgetInput2: ?string, widgetInput3: ?string,
 * widgetInput4: ?string, widgetInput5: ?string}}} options - Widget
 * initialization options
 */

/**
 * Changes width and height of widget, set in config at top of file.
 * @param {boolean} isOpen - Is widget open
 */
const resizeWidget = (isOpen) => {
  const iframe = getIframe();
  if (iframe) {
    const doc = getIframeDoc();
    const button = doc && doc.querySelector('#messenger-button');
    const config = isOpen ? WIDGET_CONFIG.opened : WIDGET_CONFIG.closed;

    if (button) button.style.display = isOpen ? 'none' : '';

    Object.keys(config).forEach((prop) => {
      if (['width', 'height', 'max-width'].includes(prop)) {
        iframe.style[prop] = config[prop];
      }
    });
  }
};

/**
 * Adds the privacy policy to the top of the conversation view
 * @param {?Element} body - Element to be inserted into with privacy policy
 */
export const addPrivacyPolicy = (body) => {
  if (body) {
    try {
      const policyWrapper = window.document.createElement('div');
      policyWrapper.id = 'privacyPolicy';
      policyWrapper.classList.add('privacy-policy');

      const customText = getTranslatedText();
      const policyText = window.document.createElement('p');
      policyText.innerHTML = customText.privacyPolicy;

      const policyLink = window.document.createElement('a');
      policyLink.target = '_blank';
      policyLink.rel = 'noreferrer noopener';
      policyLink.href = customText.privacyLink;

      policyLink.appendChild(policyText);
      policyWrapper.appendChild(policyLink);
      body.appendChild(policyWrapper);
    } catch (e) {
      error(`Failed to add privacy policy: ${e}`);
    }
  }
};

/**
 * Updates avatar icons and names in list and conversation views.
 */
export const updateAvatars = () => {
  const iframeDoc = getIframeDoc();
  if (iframeDoc)
    iframeDoc
      .querySelectorAll('.conversation-group-item')
      // Go through each conversation to update names and avatars
      .forEach(($conversation) => {
        const $img = $conversation.querySelector('img');
        const $description = $conversation.querySelector(
          '.item-content > .description'
        );

        if (
          $description &&
          BOT_NAMES.find((name) =>
            $description.textContent.includes(`${name}: `)
          )
        ) {
          $conversation.querySelector('.icon').classList.add('zendesk-bot');
          $img.src = botWidgetIcon;
          if (
            $description.firstChild &&
            $description.firstChild.textContent.includes(`Zendesk: `)
          ) {
            $description.firstChild.textContent =
              $description.firstChild.textContent.replace(
                'Zendesk: ',
                'Z Bot: '
              );
          }
        }
      });
};

/**
 * Handles the display of the Z Bot avatar.
 * @param {string} [icon="../images/blinking.gif"] - Icon to be shown
 */
export const showBotWidgetIcon = (icon = botWidgetIconBlinking) => {
  const conversation =
    window.Smooch &&
    window.Smooch.getDisplayedConversation &&
    window.Smooch.getDisplayedConversation();
  if (
    conversation &&
    conversation.messages &&
    conversation.messages.length > 0
  ) {
    const [lastMessage] = conversation.messages.slice(-1);
    const { type } = (lastMessage && lastMessage.source) || {};
    if (type === 'ultimate') {
      const iframeDoc = getIframeDoc();
      if (iframeDoc) {
        const rows = iframeDoc.querySelectorAll('.messages .row');
        const lastRow =
          rows && rows.length && rows.length > 0 && rows[rows.length - 1];
        const from = lastRow && lastRow.querySelector('.from');
        const isFromZendesk =
          from &&
          BOT_NAMES.find((name) => (from.innerText || '').includes(name));
        let businessMessages = iframeDoc.querySelectorAll(
          '.messages .row-other-user-last'
        );
        if ((rows && rows.length && rows.length === 1) || isFromZendesk) {
          businessMessages = rows;
        }
        const latestMessage =
          businessMessages &&
          businessMessages.length > 0 &&
          businessMessages[businessMessages.length - 1];
        if (latestMessage) {
          const avatar = latestMessage.querySelector('.msg-avatar img');
          if (avatar) avatar.src = icon;
        }
      }
    }
  }
};

/**
 *  Starts widget typing animations
 */
const typingStartHandler = debounce((e) => {
  if (typingStartHandler.block || e.target.value === '') return;
  typingStartHandler.block = true;
  typingStopHandler.block = false; // eslint-disable-line no-use-before-define
  showBotWidgetIcon(botWidgetIconTypingStart);
  typingStopHandler(); // eslint-disable-line no-use-before-define
}, 500);

/**
 * Stops widget typing animations
 */
const typingStopHandler = debounce(() => {
  if (typingStopHandler.block) return;
  typingStopHandler.block = true;
  typingStartHandler.block = false;
  showBotWidgetIcon(botWidgetIconTypingEnd);
}, 3000);

/**
 * Handles Z Bot avatar animations
 */
export const debouncedShowWidgetIcon = debounce((icon) => {
  showBotWidgetIcon(icon);
  if (icon !== botWidgetIconBlinking) {
    if (debouncedShowWidgetIcon.timeout)
      clearTimeout(debouncedShowWidgetIcon.timeout);
    debouncedShowWidgetIcon.timeout = setTimeout(
      () => debouncedShowWidgetIcon(botWidgetIconBlinking),
      4000
    );
  }
}, 500);

export const bindNewMessageListeners = ({
  csrfToken,
  inProduct,
  inShoppingCart,
  jwtUrl,
  locale,
  openOnInit,
  openWidgetToNewConversation,
  specificIntent,
  widgetInputs,
}) => {
  let newConversationCreated = false;

  /**
   * When widget is ready, checks for unread messages if not opened.
   */
  window.document.addEventListener('z2suncowidget.ready', () => {
    // If not automatically opening, check for unread messages
    if (!openOnInit) {
      setTimeout(() => {
        const userId = window.Smooch.getUser().id;
        window.Smooch.getConversations().every((conversation) => {
          const participant = conversation.participants.find(
            (p) => p.userId === userId
          );
          if (participant.unreadCount > 0) {
            show();
            return false;
          }
          return true;
        });
      }, 1000);
    }
    // Attempt to fetch the total number of conversations async
    setAsyncConversationsCount(window.Smooch.getConversations().length);
  });

  /**
   * When widget is opened, resizes widget, handles failover, attempts login,
   * and creates a conversation is needed.
   */
  window.Smooch.on('widget:opened', async () => {
    // Start failover activation
    // activateFailover();

    resizeWidget(true);

    // If inProduct, update display style property to show iframe
    if (inProduct && hidden()) show();

    // Check if loading, then show loading indicator
    if (getLoadingState()) {
      showLoadingIndicator(true, 'conversation');
    }

    // Check if in failover state, if so, update display
    if (!getFailoverState()) {
      // Reattempt login when opening the widget if user failed to login at any
      // point Retry on failure asynchronously, proceed as unauthenticated until
      // success
      if (jwtUrl && !getSuncoAuth()) {
        login(jwtUrl, csrfToken, true);
      }
      // Check for existing conversations and handle automatic conversation
      // creation when opening the widget based on given options
      if (
        shouldCreateConversation() ||
        (openWidgetToNewConversation && !newConversationCreated)
      )
        await createConversation({
          locale,
          inProduct,
          inShoppingCart,
          specificIntent,
          widgetInputs,
          openWidgetToNewConversation,
        });
      // Avoid creating new conversation when making subsequent attempts to open
      // the widget
      if (openWidgetToNewConversation) newConversationCreated = true;
    } else {
      activateFailover();
    }
  });

  /**
   * When widget is closed, resizes widget.
   */
  window.Smooch.on('widget:closed', () => {
    resizeWidget(false);
  });

  /**
   * When widget conversation is added, shows the loading indicator.
   */
  window.Smooch.on('conversation:added', () => {
    showLoadingIndicator(true, 'conversation');
  });
  window.Smooch.on('message:received', (message, data) => {
    const iframe = getIframe();
    // If message is recieved, show the widget gracefully
    if (iframe && hidden()) {
      iframe.style.opacity = 0;
      show();
      setTimeout(() => {
        iframe.style.opacity = 100;
      }, 750);
    }
    // Update the avatars and handle typing stop animations
    updateAvatars();
    typingStopHandler.block = true;
    typingStartHandler.block = false;
    debouncedShowWidgetIcon(botWidgetIconMessageReceivedNoBlink);
    // Check if the message recieved is for the displayed conversation before
    // hiding
    if (data && data.conversation) {
      const conversation = window.Smooch.getDisplayedConversation();
      if (conversation && conversation.id === data.conversation.id) {
        showLoadingIndicator(false, 'conversation');
      }
    }
  });

  /**
   * When widget message is sent, shows the Z Bot blinking icon and reload the
   * conversation.
   */
  window.Smooch.on('message:sent', () => {
    // Have to reload when the customer sends a message otherwise it's never
    // properly updated as sent.
    showBotWidgetIcon(botWidgetIconBlinking);
    reloadConversation();
  });

  /**
   * When widget error event is emitted, activates failover
   */
  window.document.addEventListener('z2suncowidget.error', () => {
    activateFailover();
  });
};

/**
 * Handles resizing based on existing elements in the widget sdk.
 */
const debouncedResizeObserverHandler = debounce(() => {
  const iframeDoc = getIframeDoc();
  const $panel = iframeDoc && getIframeDoc().querySelector('#mount > div');
  if ($panel) {
    const isMessengerButtonHidden = !!$panel.querySelector(
      '#messenger-button.messenger-button-hidden'
    );
    const notMediumSize = !$panel.classList.contains('widget-md');
    if (isMessengerButtonHidden && notMediumSize) {
      $panel.classList.remove('widget-sm', 'widget-lg');
      $panel.classList.add('widget-md');
      resizeWidget(isMessengerButtonHidden);
      const $errorContainer = $panel.querySelector(
        '.connection-error-container'
      );
      if ($errorContainer) {
        $errorContainer.style.maxWidth = '350px';
        $errorContainer.style.width = '100%';
      }
    }
  }
}, 25);
const resizeObserver = new MutationObserver(debouncedResizeObserverHandler);

/**
 * Handles resize observer connection and observes mutuations to iframe
 * attributes
 */
export const bindResizeObserver = () => {
  const iframeDoc = getIframeDoc();
  const $panel = iframeDoc && iframeDoc.querySelector('#mount > div');
  resizeObserver.disconnect();
  if ($panel) resizeObserver.observe($panel, { attributes: true });
};

/**
 * Handles accessibility, list page controls, conversation keystroke events,
 * invalid dates, metadata updates, icon images, and triggers resizing.
 */
const debouncedMutationObserverHandler = debounce(() => {
  if (!hidden()) {
    const iframeDoc = getIframeDoc();
    if (iframeDoc) {
      addAccessibleControls();
      const conversation =
        window.Smooch &&
        window.Smooch.getDisplayedConversation &&
        window.Smooch.getDisplayedConversation();
      if (conversation == null) {
        const button = iframeDoc.querySelector(
          '.conversation-group-footer button'
        );
        if (button) button.addEventListener('click', createConversation);
        updateAvatars(iframeDoc);
      } else {
        if (!iframeDoc.getElementById('privacyPolicy')) {
          const policyWrapper = iframeDoc.getElementById('conversation');
          addPrivacyPolicy(policyWrapper);
        }
        const textarea = iframeDoc.querySelector('textarea.message-input');
        if (textarea) textarea.addEventListener('keydown', typingStartHandler);
        iframeDoc
          .querySelectorAll('.conversation-timestamp-header')
          .forEach((header) => {
            if (
              (header.innerText || '').toLowerCase().includes('invalid date')
            ) {
              // Just updating header element display, low risk
              // eslint-disable-next-line no-param-reassign
              header.style.display = 'none';
            }
          });
      }
      updateConversationMetadata(
        debouncedMutationObserverHandler.options || {}
      );
      const headerIcon =
        iframeDoc && iframeDoc.querySelector('.header-icon > img');
      if (headerIcon) headerIcon.src = businessIconUrl;
      debouncedResizeObserverHandler();
    }
  }
}, 25);
const mutationObserver = new MutationObserver(debouncedMutationObserverHandler);

/**
 * Handles mutation observer connection and binds options that will be used for
 * mutation observer and conversation metadata updates
 * @param {{inProduct: boolean, inShoppingCart: boolean, locale: ?string,
 * openWidgetToNewConversation: boolean, specificIntent: ?string, widgetInputs:
 * {widgetInput1: ?string, widgetInput2: ?string, widgetInput3: ?string,
 * widgetInput4: ?string, widgetInput5: ?string}}} options - Widget
 * initialization options
 */
export const bindMutationObserver = (options) => {
  const $iframeDoc = getIframeDoc();
  debouncedMutationObserverHandler.options = options;
  mutationObserver.disconnect();
  if ($iframeDoc)
    mutationObserver.observe($iframeDoc, { childList: true, subtree: true });
};

/**
 * Replaces existing header controls with accessible custom controls.
 * @param {?Element} header - Widget header element
 */
export const addCustomWidgetControls = (header) => {
  if (header) {
    const closeWidgetButton = window.document.createElement('div');
    closeWidgetButton.classList.add('close-handle', 'brand-dark');
    closeWidgetButton.insertAdjacentHTML('beforeend', closeWidgetIcon);
    closeWidgetButton.addEventListener('click', hide);

    const minimizeWidgetButton = window.document.createElement('div');
    minimizeWidgetButton.classList.add('close-handle', 'brand-dark');
    minimizeWidgetButton.insertAdjacentHTML('beforeend', minimizeWidgetIcon);

    header.querySelectorAll('.close-handle').forEach((el) => el.remove());
    header.insertAdjacentElement('beforeend', minimizeWidgetButton);
    header.insertAdjacentElement('beforeend', closeWidgetButton);
  }
};
